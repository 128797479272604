import React, { useState} from 'react'

import { styles } from './styles'

const Avatar = props => {
        const [hovered, setHovered] = useState(false)

        const queryParameters = new URLSearchParams(window.location.search)
        const valUname = queryParameters.get("uname")

        function AlertMessage(){
                if(valUname === null){
                  alert("Veuiller selectionner un utilisateur dont vous voulez discuter avant de clicker sur ce discussion")
                }
              }

        return(
                <div style={props.style}
                
                >
                        <div 
                                className='transition-3'
                                style={{

                                        ...styles.avatarHello,
                                        ...{ opacity: hovered ? '1' : '0'}
                                }}
                        >Hey it's <h style={{
                                color:"blue"
                        }}>{ valUname }</h>!!!</div>

                        <div
                                className = 'transition-3'
                                onMouseOver = {() => AlertMessage()}
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                onClick={() => props.onClick && props.onClick()}
                                style = {{
                                        ...styles.chatWithMeButton,
                                        ...{ border: hovered ? '1px solid #f9f0ff' : '4px solid #7a39e0'}
                                }}
                        />
                </div>
        )
}

export default Avatar;