import axios from 'axios'
import React, {useState, } from 'react'
import "../Authentic/Authentic.css"
import { DivAuth, DivAuthButton, DivAuthInput } from '../Authentic/Authentic.element'

const AddUser2 = props => {


        const [email, setEmail] = useState('');
        const [username, setUsername] = useState('');
        const [usersecret, setUsersecret] = useState('');
        const [loading, setLoading] = useState(false);

        console.log("loading ..")

        function getOrCreateUser(){
                axios.put(
                        'https://api.chatengine.io/users/',
                        {
                                "username": username,
                                "secret": usersecret,
                                "email": email,
                        },
                        {headers: {"Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY}}
                )
                .then( window.location.href = "./support" )
        }

        function getOrCreateChat(){
          axios.put(
                  'https://api.chatengine.io/chats/',
                  {
                          "usernames": ["a@gmail.com", email],
                          
                          "is_direct_chat": true
                      },
                  {headers: {"Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY}}
          )
          .then()
  }

        function handleSubmit(event){
          event.preventDefault();
          setLoading(true)
          

          getOrCreateUser();

          getOrCreateChat()

          console.log('Sending email', email);

          console.log("email : "+email)
          console.log("username : "+username)
          console.log("usersecret : "+usersecret)

        //   getOrCreateUser(
        //           user => {
        //             console.log('Sending email with success')
        //                   props.setUser(user)
        //                   getOrCreateChat(
        //                           chat => props.setChat(chat)
        //                   )
        //           }
        //   )

  }

  return (
        <>

{/* <DivAuth>
     
      Email:
      <DivAuthInput placeholder='Your email' type="email" onChange={e => setEmail(e.target.value)}>

      </DivAuthInput>

      User Name:
      <DivAuthInput placeholder='Your Username'  onChange={e => setUsername(e.target.value)}>

      </DivAuthInput>

      User Secret:
      <DivAuthInput placeholder='Your User secret' onChange={e => setUsersecret(e.target.value)}>

      </DivAuthInput>

      <DivAuthButton>
        Inscrire
      </DivAuthButton>


</DivAuth> */}

<form onSubmit={e => handleSubmit(e)}>

      <h2>Création de compte</h2>


  

  <div class="container">
    <label for="uname"><b>Email</b></label>
    <input type="text" placeholder="Enter Email" name="uname" 
    onChange={e => setEmail(e.target.value)} required />

    <label for="psw"><b>Username</b></label>
    <input type="text" placeholder="Enter Username" name="psw" 
    onChange={e => setUsername(e.target.value)} required />
      
    <label for="psw"><b>Secret</b></label>
    <input type="password" placeholder="Enter Secret" name="psw" 
    onChange={e => setUsersecret(e.target.value)} required />

    <label for="psw"><b>Confirm Secret</b></label>
    <input type="password" placeholder="Reenter Secret" name="psw" 
    onChange={e => setUsersecret(e.target.value)} required />
        
    <button type="submit">Login</button>
    
  </div>

  <div class="container" >
    <a href="/support" class="cancelbtn">Cancel</a>
    
  </div>



      </form>
      
  
        
        
        </>
    
  )
}

export default AddUser2
