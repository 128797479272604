import axios from 'axios'
import React from 'react'
import Popup from 'reactjs-popup';

import "./listeUser.css"

const ListeUser = () => {
       
         axios.get('https://api.chatengine.io/users/',
        {headers: {"Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY}},{

        } ).then(function(response) {
                console.log(response.data)
                show(response);
        });

        

        function show(res) {
                let tab = 
                    `<tr>
                      <th>EMAIL</th>
                      <th>Username</th>
                      <th>Delete</th>
                      <th>Edit</th>
                     </tr>`;
                
                // Loop to access all rows 
                for (let r of res.data) {
                    tab += `<tr> 
                <td>${r.email} </td>
                <td>${r.username}</td>     
                <td><a href="/deleteUser?value=${r.id}">DELETE</a></td> 
                <td><a href="/editUser?value=${r.id}">EDIT</a></td> 
            </tr>`;
                }
                // Setting innerHTML as tab variable
                document.getElementById("listeData").innerHTML = tab;
            }
        // console.log(res)

       
        return (
                <body>

                <h1>Liste d'utilisateur</h1>

                <a href="/authentic">Ajouter uilisateur</a>
                
                <table id="listeData">
                    
              
                </table>

      
                
                </body>
  )
}

export default ListeUser
