import axios from 'axios';
import React, {useState} from 'react'
import "./listeUser.css"
import LOAD from "./loading.gif"

const EditUser = props => {

        const [email, setEmail] = useState("")
        const [username, setUsername] = useState("")
        const [secret, setSecret] = useState("")
        const [loading, setLoading] = useState(false)


        const [email2, setEmail2] = useState("")
        const [username2, setUsername2] = useState("")
        const [secret2, setSecret2] = useState("")
        

        const queryParameters = new URLSearchParams(window.location.search)
        const val = queryParameters.get("value")

        const mail = queryParameters.get("email")

        console.log("val : "+val);
        console.log("mail : "+mail);

        axios.get("https://api.chatengine.io/users/"+val+"/",
        
        {headers: {"Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY}},
        ).then(function (res) {
                

                var r = res.data;

                setEmail(r.email);
                setUsername(r.username);
                setSecret(r.secret);
        }
        );
       

        function getOrCreateUser(){

                axios.patch(
                        "https://api.chatengine.io/users/"+val+"/",
                        {
                            
                                "username": username2,
                                "secret": secret2,
                                "email": email2,
                        },
                        {headers: {"Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY}}
                ).then(
                  async function asyncCall() {
                    console.log('calling');
                    const result = await resolveAfter2Seconds();
                    console.log(result);

                    window.location.href = "/listeUser"
                    
                    // Expected output: "resolved"
                  }
                  
                  
                )
        }

        function resolveAfter2Seconds() {
          // document.getElementById("divload").style.display="block";
          return new Promise(resolve => {
            
            setTimeout(() => {
              console.log("loading test");
              resolve('resolved');
              setLoading(true);
              document.getElementById("divload").style.display="none";
              
            }, 1000);
          });
        }


        function handleSubmit(event){
          document.getElementById("divload").style.display="block"
                var Email = document.getElementById("emailId").value;
                var UserName = document.getElementById("emailId").value;
                var Secret = document.getElementById("secretId").value;

                

                console.log("Email : "+Email);
                console.log("UserName : "+UserName);
                console.log("Secret : "+Secret);

              

                // setEmail2(Email);
                // setUsername2(UserName);
                // setSecret2(Secret);

                console.log("email2 : "+email2);
                console.log("userName2 : "+username2);
                console.log("secret2 : "+secret2);

               

          event.preventDefault();
          
          console.log('Sending email', email2)

          getOrCreateUser();

          

       

  }
       
                
  return (
    <div>

      <div className="modal">

        <div id="divload">
          <img class="load" src={LOAD} />
        </div>
       
       
        <form onSubmit={e => handleSubmit(e)}>

      <h2>Modification de compte</h2>


  

  <div class="container">
    <label for="uname"><b>Email</b></label>
    <input type="text" placeholder={email} id="emailId" onChange={e => setEmail2(e.target.value)} name="uname" 
    required />

    <label for="psw"><b>Username</b></label>
    <input type="text" placeholder={username} id="usernameId" onChange={e => setUsername2(e.target.value)} name="psw" 
     required />
      
    <label for="psw"><b>Secret</b></label>
    <input type="password" id="secretId" onChange={e => setSecret2(e.target.value)} name="psw" 
     required />

    <label for="psw"><b>Confirm Secret</b></label>
    <input type="password" id="secretId"  name="psw" 
     required />
        
    <button type="submit">Modifier</button>
    
  </div>

  <div class="container" >
    <a href="/listeUser" class="cancelbtn">Retour</a>
  </div>



      </form>
        
      </div>
    </div>
  )
}

export default EditUser
