import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom';

const DeleteUser = () => {
      
        const queryParameters = new URLSearchParams(window.location.search)
        const type = queryParameters.get("value")

        console.log(type);

                axios.delete("https://api.chatengine.io/users/"+type+"/",
                {headers: {"Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY}},
                ).then(function name(params) {
                        window.location.href = "./listeUser"
                }
                        
                );
        
                
        
  return (
    <div>
      
    </div>
  )
}

export default DeleteUser
