import styled from "styled-components";

export const DivAuth = styled.div`
        display: inline;

`

export const DivAuthInput = styled.input`
        

`

export const DivAuthButton = styled.button`
        

`