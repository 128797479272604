import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import Home from './Home';
import SupportAdmin from './SupportAdmin';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import SupportAdmin2 from './SupportAdmin2';
import Authentic from './SupportAdmin/Authentic/Authentic';
import ListeUser from './SupportAdmin/ListeUser/listeUser';
import DeleteUser from './SupportAdmin/ListeUser/deleteUser';
import EditUser from './SupportAdmin/ListeUser/editUser';
import Login from './SupportAdmin/Login/Login';
import AddUser2 from './SupportAdmin/AddUser2/AddUser2';

// const path = window.location.pathname

ReactDOM.render(
  <React.StrictMode>
    {/* { path.indexOf('/support') === -1 ? <Home /> : <SupportAdmin /> } */}
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/support' element={<SupportAdmin/>}></Route>
        <Route path='/support2' element={<SupportAdmin2/>}></Route>
        <Route path='/Authentic' element={<Authentic/>}></Route>
        <Route path='/Adduser2' element={<AddUser2/>}></Route>
        <Route path='/listeUser' element={<ListeUser/>}></Route>
        <Route path='/deleteUser' element={<DeleteUser/>}></Route>
        <Route path='/editUser' element={<EditUser/>}></Route>
        <Route path='/Login' element={<Login/>}></Route>

      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
