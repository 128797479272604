import axios from 'axios'
import React, {useState} from 'react'

const Login = () => {

        const [email, setEmail] = useState("");
        const [secret, setSecret] = useState("");
        const [username, setUsername] = useState("");

        const [loading, setLoading] = useState(false);

     
        
        var trouve = false;
        var idVal = 0;
        var userName = null;
        var secretData = null;

        function getUser(){

                axios.get("https://api.chatengine.io/users/",
                {headers: {"Private-Key": process.env.REACT_APP_CE_PRIVATE_KEY}}
                ).then(function(response) {
                        console.log("===================")
                        for(let r of response.data){

                                
                                console.log("r.email : "+r.email)
                                console.log("r.username : "+r.username)
                                console.log("r.secret : "+r.secret)
                               
                                if(email === r.email && username === r.username){
                                        
                                        console.log("donnee connu")
                                        trouve = true;
                                        if(trouve === true){
                                                idVal = r.id;
                                                userName = r.username;
                                                secretData = r.secret;
                                                window.location.href="/support?id="+r.id+"&username="+r.username+"&secret="+r.secret;
                                                break;
                                        }
                                }else{
                                        trouve = false;
                                        

                                }
                        }

                        if(trouve === false){
                                alert("Mot de passe ou Email inconnue");
                        }else{
                                window.location.href="/support?id="+idVal+"&username="+userName+"&secret="+secretData;
                        }
                }
                )
        }

        function handleSubmit(event) {
                setLoading(true);
                event.preventDefault();
                getUser(); 
        }
               
  return (
    <>

<form onSubmit={e => {handleSubmit(e)}}>

<h2>LOG IN</h2>




<div class="container">
<label for="uname"><b>Email</b></label>
<input type="text" placeholder="Enter Email" name="uname"  onChange={e => { setEmail(e.target.value)}}
 required />

<label for="psw"><b>Secret</b></label>
<input type="text" placeholder="Enter user name" name="psw" onChange={e => { setUsername(e.target.value)}}
 required />

<button type="submit">Login</button>

</div>

<div class="container" >
<a href="/listeUser" class="cancelbtn">Cancel</a>
<span class="psw">Forgot <a href="#">password?</a></span>
</div>



</form>
      
    </>
  )
}

export default Login
