import React from 'react';

import { ChatEngine } from 'react-chat-engine';
import Login from '../SupportAdmin/Login/Login';

const SupportAdmin2 = () => {
  return (
    <>

    
    <ChatEngine
      projectID={process.env.REACT_APP_CE_PROJET_ID}
      userName='antoniorollande@gmail.com'
      userSecret='Antonio15051996'
      height='calc(100vh - 20px)'
    />
    </>
  );
}

export default SupportAdmin2;
