import axios from 'axios';
import React, {useState} from 'react';

import { ChatEngine } from 'react-chat-engine';
import "./index.css";
import Login from './Login/Login';

const SupportAdmin = () => {

  const [username, setUsername] =useState("")
  const [secret, setSecret] =useState("")
  const [loading, setLoading] = useState(false)

  const queryParameters = new URLSearchParams(window.location.search);


  const uname = queryParameters.get("uname");

  const psw = queryParameters.get("psw");

  console.log("uname : "+uname);
  console.log("psw : "+psw);


  function resolveAfter2Seconds() {
    // document.getElementById("divload").style.display="block";
    return new Promise(resolve => {
      
      setTimeout(() => {
        console.log("loading test");
        resolve('resolved');
        setLoading(true);
        Loading()
        
      }, 1000);
    });
  }

  function Loading() {
    if(uname === null && psw === null){
      document.getElementById("Divlogin").style.visibility = "visible"
    }else if(uname !== null && psw !== null){
      document.getElementById("Divlogin").style.visibility = "hidden"
    }
  };

  resolveAfter2Seconds()
  

      
  function handleSubmit(event) {
          setLoading(true);
          
          // event.preventDefault();
        
  }
       

  return (
    <>
          <div id="Divlogin" >


          <div id="login">
              <form onSubmit={e => {handleSubmit(e)}}>


              <div class="container">
              <label for="uname"><b>Username</b></label>
              <input type="text" placeholder="Enter User name" name="uname"  
              required />

              <label for="psw"><b>Secret</b></label>
              <input type="password" placeholder="Enter password" name="psw" 
              required />

              <button type="submit">Login</button>

              </div>

              <div id="action">
                  <div class="container" >
                    <a href="/" class="cancelbtn">Cancel</a>
                    <span class="psw"> <a id="createA" href="/Adduser2">Creer un compte</a></span>
                  </div>

                  
              </div>

              </form>

            </div> 

          </div>

      <div id="action">
        
          <div id="Divreconnect">
            <a id="reconnect" href="/support">Reconnecter </a> 
          </div>   

          <div id="DivRetourreconnect">
            <a id="Retourreconnect" href="/">Retour </a> 
          </div> 
        
        </div> 
    
      <ChatEngine 
            
            projectID={process.env.REACT_APP_CE_PROJET_ID}
            userName={uname}
            userSecret={psw}
            height='calc(100vh - 20px)'
      />
    
    

    </>
    
  );

  
}

export default SupportAdmin;
